import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Order, User } from '../interfaces';
import { OrderComponent } from './dialogs/order/order.component';
// import { EditTagComponent } from './edit-tag/edit-tag.component';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
})
export class OrdersComponent implements OnInit {
  env = environment;
  userDoc: AngularFirestoreDocument<User>;
  userData: User;
  user: Observable<User>;
  orderCollection: AngularFirestoreCollection<Order>;
  orders: Observable<Order[]>;
  townShipRef: string;
  filterForm: FormGroup;
  filterFormChanged: Subject<object> = new Subject<object>();
  public filteredOrders: ReplaySubject<Order[]> = new ReplaySubject<Order[]>(1);

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public dialog: MatDialog,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.filterForm = this.fb.group({
      search: [''],
      status: ['all'],
    });
    this.filterForm.valueChanges.subscribe((val) => {
      this.filterFormChanged.next(val);
    });
    this.afAuth.user.forEach((user) => {
      console.log('user', user);
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges();
      this.user.forEach((userDoc) => {
        this.userData = userDoc;
        localStorage.setItem('township', userDoc.township);
        this.townShipRef = '/township/' + userDoc.township + '/';
        this.orderCollection = this.db.collection<Order>(
          '/township/' + userDoc.township + '/orders',
          (ref) => ref.orderBy('paidDate').limitToLast(500)
        );
        this.orders = this.orderCollection.snapshotChanges().pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as Order;
              data.id = a.payload.doc['id'];
              if (!data.vouchers) {
                data.vouchers = [];
              }

              return { ...data };
            })
          )
        );
        const combinedObservable = combineLatest(
          this.orders,
          this.filterFormChanged
        );
        combinedObservable
          .pipe(debounceTime(300), distinctUntilChanged())
          .subscribe((values) => {
            console.log('values', values);
            const orders = values[0];
            const filters = values[1];
            // const filters = {
            //   showHidden: values[2],
            //   orderBy: values[3],
            //   orderDirection: values[4],
            // };
            let filteredOrders = orders.filter((order) => {
              // this.checkFilters(item, filters)
              let passesSearchFilter = true;
              let passesStatusFilter = true;
              if (filters['search'].length > 0) {
                passesSearchFilter = false;
                const searchVal = filters['search'].toLowerCase();
                if (filters['search'])
                  if (order.id.toLowerCase().includes(searchVal)) {
                    passesSearchFilter = true;
                  }
              }
              if (filters['status'] != 'all') {
                if (filters['status'] == 'paid' && order.status != 'paid') {
                  passesStatusFilter = false;
                } else if (
                  filters['status'] == 'done' &&
                  order.status != 'done'
                ) {
                  passesStatusFilter = false;
                }
              }
              if (passesSearchFilter && passesStatusFilter) {
                return true;
              } else {
                return false;
              }
            });
            // this.orderDirection = 'desc';
            // console.log('orderBy', filters.orderBy);
            filteredOrders = filteredOrders.sort((a: Order, b: Order) => {
              if (a.paidDate < b.paidDate) {
                return 1;
              } else {
                return -1;
              }
              // return 0;
            });
            // if (filters.orderDirection === 'desc') {
            //   filteredUsers.reverse();
            // }
            console.log('filteredOrders', filteredOrders);
            // this.totalUsers = filteredUsers.length;
            this.filteredOrders.next(filteredOrders);
            // this.filteredOrders.next(
            //   this.allUsers.pipe(
            //     map((items) => items.filter((item) => this.checkFilters(item)))
            //   )
            // );
          });
        this.filterFormChanged.next(this.filterForm.value);
      });
    });
  }

  getStatusColor(order: Order) {
    let color = 'white';
    switch (order.status) {
      case 'reserved':
        color = 'red';
        break;
      case 'paid':
        color = '#fc9f5b';
        break;
      case 'done':
        color = '#62C890';
        break;
    }
    return color;
  }
  getCurrencyString(number) {
    if (number.toString().indexOf('.') == -1) {
      return `€${number.toString()},-`;
    }
    return `€${number.toFixed(2).replace('.', ',')}`;
  }
  openOrder(order: Order) {
    const orderRef = this.db.doc(
      `township/${this.userData.township}/orders/${order.id}`
    );
    this.dialog.open(OrderComponent, {
      width: '373px',
      data: { orderRef: orderRef },
    });
  }
}
