import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AngularFirestoreCollection,
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { VoucherGroup, Voucher, Township } from '../interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VoucherGroupEditComponent } from './voucher-group-edit/voucher-group-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { VoucherGroupDetailComponent } from './voucher-group-detail/voucher-group-detail.component';
import * as XLSX from 'xlsx';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActionListComponent } from './dialogs/action-list/action-list.component';

@Component({
  selector: 'app-voucher-management',
  templateUrl: './voucher-management.component.html',
  styleUrls: ['./voucher-management.component.scss'],
})
export class VoucherManagementComponent implements OnInit {
  env = environment;
  voucherGroupCollection: AngularFirestoreCollection<VoucherGroup>;
  voucherGroups: Observable<VoucherGroup[]>;
  townshipDoc: AngularFirestoreDocument<Township>;
  $township: Observable<Township>;
  township: Township;
  public chartScheme = {
    domain: ['#62C590', '#cff584', '#F5DC69', '#FC9F5B', '#1A697F'],
  };

  constructor(
    public db: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.townshipDoc = this.db.doc<Township>(
      `township/${localStorage.getItem('township')}`
    );
    this.$township = this.townshipDoc.valueChanges();
    this.$township.subscribe((value) => {
      this.township = value;
      console.log('township', this.township);
    });

    this.voucherGroupCollection = this.db.collection(
      '/township/' + localStorage.getItem('township') + '/voucherGroups',
      (ref) => ref.orderBy('name')
    );
    this.voucherGroups = this.voucherGroupCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as VoucherGroup;
          data.id = a.payload.doc['id'];
          // if (data.id === 'qGabjW7fPoWp7nGa8Lt5') {
          //   console.log('data.totalVouchers', data.activatedVouchers);
          // }

          data.totalVouchers = data.totalVouchers ? data.totalVouchers : 0;
          data.paidVouchers = data.paidVouchers ? data.paidVouchers : 0;
          data.toBeActivated = data.toBeActivated ? data.toBeActivated : 0;

          data.claimedVouchers = data.claimedVouchers
            ? data.claimedVouchers
            : 0;
          data.activatedVouchers = data.activatedVouchers
            ? data.activatedVouchers
            : 0;
          data.reservedVouchers = data.reservedVouchers
            ? data.reservedVouchers
            : 0;
          let paidVouchers = data.paidVouchers;
          let claimedVouchers = data.claimedVouchers - paidVouchers;
          let activatedVouchers = data.activatedVouchers - data.claimedVouchers;
          let reservedVouchers = data.reservedVouchers + data.toBeActivated;
          let availableVouchers =
            data.totalVouchers -
            activatedVouchers -
            reservedVouchers -
            claimedVouchers -
            paidVouchers;

          if (data.totalVouchers > data.maxVouchers) {
            data.totalVouchers = data.maxVouchers;
            let newTotalVouchers = data.maxVouchers;

            if (paidVouchers > newTotalVouchers) {
              paidVouchers = newTotalVouchers;
              console.log('paidVouchers is more then newTotalVouchers');
              newTotalVouchers = 0;
            } else {
              newTotalVouchers = newTotalVouchers - paidVouchers;
              console.log('newTotalVouchers', newTotalVouchers);
            }
            if (claimedVouchers > newTotalVouchers) {
              claimedVouchers = newTotalVouchers;
              newTotalVouchers = 0;
            } else {
              // claimedVouchers = claimedVouchers - data.paidVouchers;
              newTotalVouchers = newTotalVouchers - claimedVouchers;
              console.log('newTotalVouchers', newTotalVouchers);
            }
            if (reservedVouchers > newTotalVouchers) {
              reservedVouchers = newTotalVouchers;
              newTotalVouchers = 0;
            } else {
              // reservedVouchers = reservedVouchers - data.claimedVouchers;
              newTotalVouchers -= reservedVouchers;
              console.log('newTotalVouchers', newTotalVouchers);
            }
            if (activatedVouchers > newTotalVouchers) {
              activatedVouchers = newTotalVouchers;
              newTotalVouchers = 0;
            } else {
              // activatedVouchers = activatedVouchers - data.reservedVouchers;
              newTotalVouchers -= activatedVouchers;
              console.log('newTotalVouchers', newTotalVouchers);
              // console.log('newTotalVouchers', newTotalVouchers);
              // availableVouchers -= newTotalVouchers;
            }
            availableVouchers = newTotalVouchers;
          }
          data.statisticsChart = [
            {
              name: 'Beschikbaar',
              value: availableVouchers,
            },
            {
              name: 'Gereserveerd',
              value: reservedVouchers,
            },
            {
              name: 'Geactiveerd',
              value: activatedVouchers,
            },
            {
              name: 'Geclaimd',
              value: claimedVouchers,
            },
            {
              name: 'Betaald',
              value: paidVouchers,
            },
          ];
          return { ...data };
        })
      )
    );
    this.voucherGroups.subscribe((voucherGroups) => {
      console.log('voucherGroups', voucherGroups);
    });
  }

  openVoucherGroupDetails(voucherGroup) {
    this.dialog.open(VoucherGroupDetailComponent, {
      width: '373px',
      data: { voucherGroup },
    });
  }
  openEditVoucherGroup(voucherGroup?) {
    this.dialog.open(VoucherGroupEditComponent, {
      width: '500px',
      data: { voucherGroup, township: this.township },
    });
  }
  // async deleteVoucherGroup(voucherGroup) {
  //   console.log('voucherGroup', voucherGroup);
  //   const voucherGroupRef = this.db.doc(`/township/${localStorage.getItem('township')}/voucherGroup/${voucherGroup.id}`)
  //   const voucherRef = this.db.collection(
  //     '/township/' + localStorage.getItem('township') + '/vouchers/',
  //     (ref) =>
  //       ref.where('voucherGroupId', '==', voucherGroup.id).orderBy('number')
  //   );
  //   const vouchersDocs = (await voucherRef.get().toPromise()).docs;
  //   console.log('vouchersDocs', vouchersDocs);
  //   const batches = [];
  //   let batchIndex = 0;
  //   let operationCounter = 0;
  //   batches[0] = this.db.firestore.batch();

  //   vouchersDocs.forEach((voucherDoc) => {
  //     if (operationCounter == 500) {
  //       batchIndex++;
  //       operationCounter = 0;
  //       batches[batchIndex] = this.db.firestore.batch();
  //     }
  //     console.log(voucherDoc.ref.path);
  //     batches[batchIndex].delete(voucherDoc.ref);
  //     operationCounter++;
  //   });
  //   batches.forEach(async (batch) => {
  //     console.log('batch', batch);
  //     await batch.commit();
  //   });
  //   await voucherGroupRef.delete();
  //   this.snackBar.open('Bongroep verwijderd.', 'X', {
  //     duration: 3000,
  //   });
  // }

  copyIframeUrl(voucherGroup, language?) {
    if (!language) {
      language = '';
    } else {
      language = language + '.';
    }
    const el = document.createElement('textarea');
    let type = 'activate';
    if (voucherGroup.type === 'sell') {
      type = 'buy';
    }
    if (this.env.production) {
      el.value = `https://${language}frame.${
        this.env.prefix
      }bon.nl/${type}/${localStorage.getItem('township')}/${voucherGroup.id}`;
    } else {
      el.value = `https://dev.${language}frame.lokalebon.nl/${type}/${localStorage.getItem(
        'township'
      )}/${voucherGroup.id}`;
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.snackBar.open('Link gekopieerd.', 'X', {
      duration: 3000,
    });
  }
  async recalcStats(voucherGroup) {
    const requestUrl = `${environment.functionsUrl}/httpRecalcStatistics`;
    this.http
      .post(requestUrl, {
        type: 'voucherGroup',
        townshipId: localStorage.getItem('township'),
        voucherGroupId: voucherGroup.id,
      })
      .subscribe((res) => {
        console.log(res);
      });
    this.snackBar.open(
      'Statistieken worden herberekend, even geduld a.u.b.',
      'X',
      {
        duration: 5000,
      }
    );
  }

  async exportVoucherGroup(voucherGroup) {
    console.log('voucherGroup', voucherGroup);
    const voucherRef = this.db.collection(
      '/township/' + localStorage.getItem('township') + '/vouchers/',
      (ref) =>
        ref.where('voucherGroupId', '==', voucherGroup.id).orderBy('number')
    );
    const vouchersArray = [];
    const vouchersObservable = await voucherRef.get();
    await vouchersObservable.forEach((vouchers) => {
      // console.log('vouchers', vouchers);
      vouchers.forEach((voucherDoc) => {
        const voucher = voucherDoc.data() as Voucher;
        const exportVoucherObj = {};
        exportVoucherObj['Vouchernummer'] = voucher.number
          ? voucher.number
          : '';
        exportVoucherObj['Waarde'] =
          voucher.value !== null ? Number(voucher.value) : '';
        exportVoucherObj['Te betalen waarde'] = voucher.amountToPayOrg
          ? Number(voucher.amountToPayOrg)
          : '';
        exportVoucherObj['Naam'] = voucher.name ? voucher.name : '';
        exportVoucherObj['Telefoonnummer'] = voucher.phone ? voucher.phone : '';
        exportVoucherObj['E-mail'] = voucher.email ? voucher.email : '';
        exportVoucherObj['Postcode'] = voucher.postal ? voucher.postal : '';
        // exportVoucherObj['Image Url'] = voucher.imageUrl
        //   ? voucher.imageUrl
        //   : '';
        exportVoucherObj['Huisnummer'] = voucher.houseNumber
          ? voucher.houseNumber
          : '';
        exportVoucherObj['Toevoeging'] = voucher.houseNumberAddition
          ? voucher.houseNumberAddition
          : '';
        exportVoucherObj['Kenmerk'] = voucher.type
          ? voucher.type.toString()
          : '';
        exportVoucherObj['Activatiedatum'] = voucher.activateDate
          ? voucher.activateDate.toDate().getDate() +
            '-' +
            (voucher.activateDate.toDate().getMonth() + 1) +
            '-' +
            voucher.activateDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Activatiebedrijf'] = voucher.activateOrganisation
          ? voucher.activateOrganisation
          : '';
        exportVoucherObj['Claimdatum'] = voucher.claimDate
          ? voucher.claimDate.toDate().getDate() +
            '-' +
            (voucher.claimDate.toDate().getMonth() + 1) +
            '-' +
            voucher.claimDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Claimbedrijf'] = voucher.claimOrganisation
          ? voucher.claimOrganisation
          : '';
        exportVoucherObj['Betaaldatum'] = voucher.paidDate
          ? voucher.paidDate.toDate().getDate() +
            '-' +
            (voucher.paidDate.toDate().getMonth() + 1) +
            '-' +
            voucher.paidDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Vervaldatum'] = voucher.validUntilDate
          ? voucher.validUntilDate.toDate().getDate() +
            '-' +
            (voucher.validUntilDate.toDate().getMonth() + 1) +
            '-' +
            voucher.validUntilDate.toDate().getFullYear()
          : '';
        exportVoucherObj['Herinnering verstuurd'] = voucher.reminderSend
          ? voucher.reminderSend
          : '';

        // if (voucherGroup.addFieldsToExport) {
        //   exportVoucherObj['Wie pakt op'] = voucher.responsible
        //     ? voucher.responsible
        //     : '';
        //   exportVoucherObj['Contact gehad op'] = voucher.contactedOn
        //     ? voucher.contactedOn
        //     : '';
        //   exportVoucherObj['Afspraak gepland op'] = voucher.appointmentDate
        //     ? voucher.appointmentDate
        //     : '';
        //   exportVoucherObj['Versterkte materialen'] = voucher.usedMaterials
        //     ? voucher.usedMaterials
        //     : '';
        //   exportVoucherObj['Evt opmerkingen'] = voucher.usedMaterials
        //     ? voucher.usedMaterials
        //     : '';
        // }
        vouchersArray.push(exportVoucherObj);
      });
    });
    if (vouchersArray.length > 0) {
      console.log('vouchers', vouchersArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(vouchersArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');

      // /* save to file */
      XLSX.writeFile(wb, this.env.name + '.' + voucherGroup.name + '.xlsx');
    } else {
      // Nothing to export
      this.snackBar.open('Er zijn geen bonnen om te exporteren.', 'X', {
        duration: 5000,
      });
    }
  }

  openActionList(voucherGroup) {
    this.dialog.open(ActionListComponent, {
      width: '375px',
      data: { voucherGroup: voucherGroup },
    });
  }
}
