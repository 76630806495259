import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  User,
  TownshipUser,
  Township,
  Organisation,
  Address,
  Voucher,
  VoucherGroup,
  InvoiceInfo,
} from '../interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  FormGroup,
  Validators,
  FormControl,
  FormBuilder,
} from '@angular/forms';
import * as XLSX from 'xlsx';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { firestore } from 'firebase';
import { Router } from '@angular/router';
import { ContinueComponent } from './dialog/continue-dialog/continue.component';
import { environment } from 'src/environments/environment';
import { AngularFireStorage } from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { ForceApiSyncDialogComponent } from './dialog/force-api-sync-dialog/force-api-sync-dialog.component';
import { SetupPaymentDialogComponent } from './dialog/setup-payment-dialog/setup-payment-dialog.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, AfterViewInit {
  @ViewChild('primary', { static: false }) primary: ElementRef;
  @ViewChild('accent', { static: false }) accent: ElementRef;
  env = environment;
  settingsForm: FormGroup;
  userId: Observable<string>;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  townshipDoc: AngularFirestoreDocument<Township>;
  township: Township;
  townshipId: string;
  townshipUserDoc: AngularFirestoreDocument<TownshipUser>;
  townshipUser: Observable<TownshipUser>;
  accentToggle: boolean;
  primaryToggle: boolean;
  // Used for file upload
  // storeData: any;
  addressEmptyOnLoad: boolean;
  addressFileUploaded: File;
  addressWorksheet: any;
  postalsEmptyOnLoad = true;
  postalsFileUploaded: File;
  postalsWorksheet: any;
  saving: boolean;
  sisowProfileId: string;

  formChanged: boolean;
  deleteHeaderImg: boolean;

  invoiceData: {};
  checkboxCheck = false;

  prefixes: any[] = [
    { name: 'Lokale Bon', db: 'lokale' },
    { name: 'Groene Bon', db: 'groene' },
  ];

  headerImgFileAlreadyUploaded: boolean;
  headerImgFileUploaded: File;
  headerImgUrl: string;

  invoicePdfFileAlreadyUploaded: boolean;
  invoicePdfFileUploaded: File;
  invoicePdfUrl: string;
  deleteInvoicePdf: boolean;

  voucherPdfFileAlreadyUploaded: boolean;
  voucherPdfFileUploaded: File;
  voucherPdfUrl: string;
  deleteVoucherPdf: boolean;

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private storage: AngularFireStorage,
    private fb: FormBuilder,
    private http: HttpClient
  ) {}
  rgbToHex(rgb) {
    const rgbArray = rgb.substring(4, rgb.length - 1).split(', ');
    console.log(rgbArray);
    let r = Number(rgbArray[0]).toString(16);
    let g = Number(rgbArray[1]).toString(16);
    let b = Number(rgbArray[2]).toString(16);
    if (r.length === 1) {
      r = '0' + r;
    }
    if (g.length === 1) {
      g = '0' + g;
    }
    if (b.length === 1) {
      b = '0' + b;
    }

    return '#' + r + g + b;

    // return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      if (!this.settingsForm.value.primaryColor) {
        this.settingsForm.controls.primaryColor.setValue(
          this.rgbToHex(getComputedStyle(this.primary.nativeElement).color)
        );
      }
      if (!this.settingsForm.value.accentColor) {
        this.settingsForm.controls.accentColor.setValue(
          this.rgbToHex(getComputedStyle(this.accent.nativeElement).color)
        );
      }
    }, 500);
  }

  ngOnInit() {
    console.log('prefixes', this.prefixes);
    this.settingsForm = this.fb.group({
      name: new FormControl('', [Validators.required]),
      url: new FormControl('', [Validators.required]),
      termsUrl: new FormControl('', [Validators.required]),
      voucherPrefix: new FormControl('', [Validators.required]),
      prefix: new FormControl('', [Validators.required]),
      sisowProfileId: new FormControl(''),
      primaryColor: ['', [Validators.maxLength(7), Validators.minLength(7)]],
      accentColor: ['', [Validators.maxLength(7), Validators.minLength(7)]],
    });
    this.afAuth.user.forEach((user) => {
      console.log('user', user);
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges();
      this.user.forEach(async (userDoc) => {
        // console.log('userDoc.voucherPrefix', );
        this.townshipId = userDoc.township;
        this.townshipDoc = this.db.doc<Township>(
          'township/' + userDoc.township
        );
        this.townshipUserDoc = this.db.doc<TownshipUser>(
          'township/' + userDoc.township + '/users/' + user.uid
        );
        this.township = (await this.townshipDoc.ref.get()).data() as Township;
        this.addressEmptyOnLoad = (
          await this.townshipDoc.collection('addresses').ref.limit(1).get()
        ).empty;
        console.log('this.township', this.township);
        if (this.township.postals) {
          this.postalsEmptyOnLoad = false;
        }
        if (this.township.useHeaderImage) {
          this.headerImgFileAlreadyUploaded = true;
          this.headerImgUrl = this.township.headerImageUrl;
        }
        if (this.township.useInvoicePdf) {
          this.invoicePdfFileAlreadyUploaded = true;
          this.invoicePdfUrl = this.township.invoicePdfUrl;
        }
        if (this.township.useVoucherPdf) {
          this.voucherPdfFileAlreadyUploaded = true;
          this.voucherPdfUrl = this.township.voucherPdfUrl;
        }
        if (this.township.invoiceInfo && this.township.sisow) {
          this.checkboxCheck = true;
        }
        this.settingsForm.patchValue(this.township);
      });
    });
    this.settingsForm.valueChanges.subscribe((value) => {
      if (this.settingsForm.dirty) {
        this.formChanged = true;
      }
    });
  }

  async exportAllVouchers() {
    const voucherRef = this.db.collection(
      '/township/' + localStorage.getItem('township') + '/vouchers/'
    );
    const voucherGroupsRef = this.db.collection(
      '/township/' + localStorage.getItem('township') + '/voucherGroups/'
    );
    const voucherGroupsObservable = voucherGroupsRef.get();
    const vouchersArray = [];
    const vouchersObservable = voucherRef.get();
    voucherGroupsObservable.forEach((voucherGroups) => {
      console.log('voucherGroups', voucherGroups);
      vouchersObservable.forEach((vouchers) => {
        // console.log('vouchers', vouchers);
        vouchers.forEach(async (voucherDoc) => {
          const voucher = voucherDoc.data() as Voucher;
          let voucherGroupName = '';
          if (voucher.voucherGroupId) {
            const voucherGroupDoc = voucherGroups.docs.find(
              (voucherGroup) => voucherGroup.id === voucher.voucherGroupId
            );
            if (voucherGroupDoc) {
              voucherGroupName = (voucherGroupDoc.data() as VoucherGroup).name;
            }
          } else {
            console.log('voucher has no voucherGroupId', voucher);
          }

          const exportVoucherObj = {};
          exportVoucherObj['Vouchernummer'] = voucher.number
            ? voucher.number
            : '';
          exportVoucherObj['Waarde'] =
            voucher.value !== null ? Number(voucher.value) : '';
          exportVoucherObj['Te betalen waarde'] = voucher.amountToPayOrg
            ? Number(voucher.amountToPayOrg)
            : '';
          exportVoucherObj['Naam'] = voucher.name ? voucher.name : '';
          exportVoucherObj['E-mail'] = voucher.email ? voucher.email : '';
          exportVoucherObj['Postcode'] = voucher.postal ? voucher.postal : '';
          exportVoucherObj['Image Url'] = voucher.imageUrl
            ? voucher.imageUrl
            : '';
          exportVoucherObj['Huisnummer'] = voucher.houseNumber
            ? voucher.houseNumber
            : '';
          exportVoucherObj['Toevoeging'] = voucher.houseNumberAddition
            ? voucher.houseNumberAddition
            : '';
          exportVoucherObj['Kenmerk'] = voucher.type
            ? voucher.type.toString()
            : '';
          exportVoucherObj['Activatiedatum'] = voucher.activateDate
            ? voucher.activateDate.toDate().getDate() +
              '-' +
              (voucher.activateDate.toDate().getMonth() + 1) +
              '-' +
              voucher.activateDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Activatiebedrijf'] = voucher.activateOrganisation
            ? voucher.activateOrganisation
            : '';
          exportVoucherObj['Claimdatum'] = voucher.claimDate
            ? voucher.claimDate.toDate().getDate() +
              '-' +
              (voucher.claimDate.toDate().getMonth() + 1) +
              '-' +
              voucher.claimDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Claimbedrijf'] = voucher.claimOrganisation
            ? voucher.claimOrganisation
            : '';
          exportVoucherObj['Betaaldatum'] = voucher.paidDate
            ? voucher.paidDate.toDate().getDate() +
              '-' +
              (voucher.paidDate.toDate().getMonth() + 1) +
              '-' +
              voucher.paidDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Vervaldatum'] = voucher.validUntilDate
            ? voucher.validUntilDate.toDate().getDate() +
              '-' +
              (voucher.validUntilDate.toDate().getMonth() + 1) +
              '-' +
              voucher.validUntilDate.toDate().getFullYear()
            : '';
          exportVoucherObj['Herinnering verstuurd'] = voucher.reminderSend
            ? voucher.reminderSend
            : '';
          exportVoucherObj['Bongroep'] = voucherGroupName
            ? voucherGroupName
            : '';
          vouchersArray.push(exportVoucherObj);

          if (vouchers.size === vouchersArray.length) {
            if (vouchersArray.length > 0) {
              console.log('vouchers', vouchersArray);
              const ws: XLSX.WorkSheet =
                XLSX.utils.json_to_sheet(vouchersArray); // converts a DOM TABLE element to a worksheet
              const wb: XLSX.WorkBook = XLSX.utils.book_new();
              XLSX.utils.book_append_sheet(wb, ws, 'Vouchers');

              // /* save to file */
              XLSX.writeFile(wb, this.env.name + '.' + 'Alle Bonnen' + '.xlsx');
            } else {
              // Nothing to export
              this.snackBar.open('Er zijn geen bonnen om te exporteren.', 'X', {
                duration: 5000,
              });
            }
          }
          // });
        });
      });
    });
  }
  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const saveObj = { ...this.settingsForm.value } as Township;

    if (saveObj.prefix.length === 3) {
      // console.log('voucher prefix isnt good enough');
      if (
        !this.urlCheck(saveObj.url, 'url') ||
        !this.urlCheck(saveObj.termsUrl, 'terms')
      ) {
        return;
      }

      if (this.invoiceData) {
        saveObj.invoiceInfo = this.invoiceData as InvoiceInfo;
      }
      // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
      Object.keys(saveObj).forEach((key) => {
        if (saveObj[key] == null) {
          saveObj[key] = firestore.FieldValue.delete();
        }
      });
      // console.log('worksheet', this.addressWorksheet);

      if (this.headerImgFileUploaded) {
        const filePath = `${this.townshipId}/images/${this.townshipId}`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.headerImgFileUploaded
        );
        saveObj.headerImageUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useHeaderImage = true;
      }

      if (this.invoicePdfFileUploaded) {
        const filePath = `${this.townshipId}/pdf-templates/factuur.pdf`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.invoicePdfFileUploaded
        );
        saveObj.invoicePdfUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useInvoicePdf = true;
      }

      if (this.voucherPdfFileUploaded) {
        const filePath = `${this.townshipId}/pdf-templates/bon-template.pdf`;
        const uploadTask = await this.storage.upload(
          filePath,
          this.voucherPdfFileUploaded
        );
        saveObj.voucherPdfUrl = await uploadTask.ref.getDownloadURL();
        saveObj.useVoucherPdf = true;
      }

      if (this.deleteHeaderImg) {
        this.deleteFile('headerImg', 'img');
        saveObj.headerImageUrl = '';
        saveObj.useHeaderImage = false;
      }
      if (this.deleteInvoicePdf) {
        this.deleteFile('invoicePdf', 'pdf');
        saveObj.invoicePdfUrl = '';
        saveObj.useInvoicePdf = false;
      }
      if (this.deleteVoucherPdf) {
        this.deleteFile('voucherPdf', 'pdf');
        saveObj.voucherPdfUrl = '';
        saveObj.useVoucherPdf = false;
      }

      // if (this.sisowProfileId) {
      //   // console.log('profileId', this.sisowProfileId);
      //   saveObj.sisowProfile = this.sisowProfileId;
      // }
      const batches = [];
      let batchIndex = 0;
      let operationCounter = 0;
      let addressesSpreadsheetError;
      if (this.addressWorksheet) {
        const spreadsheet = {};
        Object.keys(this.addressWorksheet).forEach((key) => {
          try {
            if (
              key !== '!ref' &&
              key !== '!margins' &&
              key !== '!autofilter' &&
              key !== '!merges'
            ) {
              const rowId = key.match(/\d+/g).toString();
              const colId = key.match(/[a-zA-Z]+/g).toString();
              if (!spreadsheet[rowId]) {
                spreadsheet[rowId] = {};
              }
              spreadsheet[rowId][colId] = this.addressWorksheet[key].w;
            }
          } catch (error) {
            console.log('key with error:', key);
            console.error(error);
          }
        });
        // console.log('spreadsheet', spreadsheet);
        const columnNames = spreadsheet[1];
        Object.keys(columnNames).forEach((key) => {
          // console.log('key', key);
          key = key;
          const val = columnNames[key].toLowerCase();
          switch (val) {
            default:
              delete columnNames[key];
              break;
            case 'postcode':
              columnNames[key] = 'postal';
              break;
            case 'huisnummer':
              columnNames[key] = 'houseNumber';
              break;
            case 'toevoeging':
              columnNames[key] = 'houseNumberAddition';
              break;
            case 'type':
              columnNames[key] = 'type';
              break;
            case 'straatnaam':
              columnNames[key] = 'street';
              break;
            case 'woonplaats':
              columnNames[key] = 'place';
              break;
          }
          // console.log('columnNames', columnNames);
        });
        delete spreadsheet[1];
        // console.log('columnNames', columnNames);
        batches[0] = this.db.firestore.batch();
        Object.keys(spreadsheet).forEach((key) => {
          const rowObj = {} as any;
          Object.keys(spreadsheet[key]).forEach((colKey) => {
            const colName = columnNames[colKey];
            // console.log('spreadsheet[key][colKey]', spreadsheet[key][colKey]);
            // console.log('colName', colName);
            if (colName && spreadsheet[key][colKey].length !== 0) {
              rowObj[colName] = spreadsheet[key][colKey];
              // set rowObj with value from spreadsheet for ALL fields
              if (colName === 'postal') {
                rowObj[colName] = spreadsheet[key][colKey]
                  .toUpperCase()
                  .replace(/ /g, '');
              }
              if (colName === 'houseNumberAddition') {
                rowObj[colName] = spreadsheet[key][colKey]
                  .toLowerCase()
                  .replace(/ /g, '');
              }
            }
          });
          // console.log('rowObj', rowObj.type);
          let newtypes = [];
          if (rowObj.type && rowObj.type.includes(',')) {
            newtypes = rowObj.type
              .split(',')
              .map((item: string) => item.trim().toLowerCase());
          } else if (rowObj.type && !rowObj.type.includes(',')) {
            // cannot use else because of empty excel field
            newtypes = [rowObj.type.toString().toLowerCase()];
          }

          if (newtypes && newtypes.length > 0) {
            newtypes.forEach(async (type) => {
              const addresTypeRef = this.db.doc(
                `township/${this.townshipId}/addresTypes/${type}`
              );
              if (operationCounter == 500) {
                batchIndex++;
                operationCounter = 0;
                batches[batchIndex] = this.db.firestore.batch();
              }
              batches[batchIndex].set(
                addresTypeRef.ref,
                { type: type },
                { merge: true }
              );
              operationCounter++;
            });
          }
          if (newtypes.length > 0) {
            rowObj.type = newtypes;
          } else {
            rowObj.type = firestore.FieldValue.delete();
          }
          // Only upload voucher if it has both of the required values
          if (rowObj.postal && rowObj.houseNumber) {
            const addressString =
              rowObj.postal +
              rowObj.houseNumber +
              (rowObj.houseNumberAddition ? rowObj.houseNumberAddition : '');
            // console.log('addressString', addressString);
            const ref = this.db
              .collection(this.townshipDoc.ref.path + '/addresses/')
              .doc(addressString);
            // console.log('ref', ref.ref.path);
            if (operationCounter == 500) {
              batchIndex++;
              operationCounter = 0;
              batches[batchIndex] = this.db.firestore.batch();
            }
            batches[batchIndex].set(ref.ref, rowObj, { merge: true });
            operationCounter++;
          } else {
            addressesSpreadsheetError = true;
          }
        });
        // console.loag('batches', batches);
        // Object.keys(batches).forEach((key) => {
        //   const currentRow = spreadsheetObj[key];
        // });
      }

      let postalsSpreadsheetError;
      if (this.postalsWorksheet) {
        const postals = [];
        const spreadsheet = {};
        Object.keys(this.postalsWorksheet).forEach((key) => {
          try {
            if (
              key !== '!ref' &&
              key !== '!margins' &&
              key !== '!autofilter' &&
              key !== '!merges'
            ) {
              const rowId = key.match(/\d+/g).toString();
              const colId = key.match(/[a-zA-Z]+/g).toString();
              if (!spreadsheet[rowId]) {
                spreadsheet[rowId] = {};
              }
              spreadsheet[rowId][colId] = this.postalsWorksheet[key].w;
            }
          } catch (error) {
            console.log('key with error:', key);
            console.error(error);
          }
        });
        // console.log('spreadsheet', spreadsheet);
        const columnNames = spreadsheet[1];
        Object.keys(columnNames).forEach((key) => {
          // console.log('key', key);
          key = key;
          const val = columnNames[key].toLowerCase();
          switch (val) {
            default:
              delete columnNames[key];
              break;
            case 'postcode cijfers':
              columnNames[key] = 'postal';
              break;
          }
        });
        delete spreadsheet[1];
        // console.log('columnNames', columnNames);
        Object.keys(spreadsheet).forEach((key) => {
          const rowObj = {} as Address;
          Object.keys(spreadsheet[key]).forEach((colKey) => {
            const colName = columnNames[colKey];
            // console.log(spreadsheet[key][colKey]);
            // console.log('colName', colName);
            if (colName && spreadsheet[key][colKey].length !== 0) {
              rowObj[colName] = spreadsheet[key][colKey];
            }
          });
          // console.log('rowObj', rowObj);

          // Only upload voucher if it has both of the required values
          if (rowObj.postal && rowObj.postal.length === 4) {
            postals.push(rowObj.postal);
          } else {
            postalsSpreadsheetError = true;
          }
        });
        // console.loag('batches', batches);
        // Object.keys(batches).forEach((key) => {
        //   const currentRow = spreadsheetObj[key];
        // });
        saveObj.postals = postals;
      }
      if (!addressesSpreadsheetError || !postalsSpreadsheetError) {
        console.log('this.townshipDoc', this.townshipDoc.ref.path);
        console.log('saveObj', saveObj);
        await this.townshipDoc.set(saveObj, { merge: true });
        batches.forEach(async (batch) => {
          console.log(batch);
          await batch.commit();
        });

        this.router.navigateByUrl('/dashboard-township');
      } else {
        this.saving = false;
        if (!addressesSpreadsheetError) {
          this.snackBar.open(
            'Eén of meer adressen bevatten niet alle vereisde velden: postcode en huisnummer.',
            'X',
            {
              duration: 3000,
            }
          );
        } else if (!postalsSpreadsheetError) {
          this.snackBar.open(
            'Eén of meer postcodecijfers bevatten niet alle vereisde velden: postcodecijfers (4 karakters).',
            'X',
            {
              duration: 3000,
            }
          );
        }
      }
    } else {
      this.settingsForm.controls['prefix'].setErrors({ incorrect: true });
      this.saving = false;
    }
  }
  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }
  uploadedFile(event, type) {
    this.formChanged = true;
    const file = event.target.files[0] as File;
    if (type === 'postals') {
      this.postalsFileUploaded = event.target.files[0];
      this.readExcel(type);
    } else if (type === 'address') {
      this.addressFileUploaded = event.target.files[0];
      this.readExcel(type);
    } else if (type === 'headerImg') {
      if (file.size > 2048000) {
        this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      } else {
        this.headerImgFileUploaded = file;
      }
    } else if (type === 'invoicePdf') {
      this.invoicePdfFileUploaded = file;
      console.log('pdf', this.invoicePdfFileUploaded);
    } else if (type === 'voucherPdf') {
      this.voucherPdfFileUploaded = file;
      console.log('pdf', this.voucherPdfFileUploaded);
    }
  }
  readExcel(type) {
    const readFile = new FileReader();
    readFile.onload = (e) => {
      const storeData = readFile.result as any;
      const data = new Uint8Array(storeData);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      if (type === 'postals') {
        this.postalsWorksheet = workbook.Sheets[firstSheetName];
        console.log('this.postalsWorksheet', this.postalsWorksheet);
      } else if (type === 'address') {
        this.addressWorksheet = workbook.Sheets[firstSheetName];
        console.log('this.addressWorksheet', this.addressWorksheet);
      }
    };
    if (type === 'postals') {
      readFile.readAsArrayBuffer(this.postalsFileUploaded);
    } else if (type === 'address') {
      readFile.readAsArrayBuffer(this.addressFileUploaded);
    }
  }
  async exportAddresses() {
    const exportArray = [];

    const addressesRef = this.db.collection(
      this.townshipDoc.ref.path + '/addresses/'
    );
    const addressesObservable = await addressesRef.get();
    await addressesObservable.forEach((addresses) => {
      addresses.forEach((addressDoc) => {
        const exportObj = {};
        const address = addressDoc.data() as Address;
        console.log('address', address);
        if (address.houseNumber.includes(' ')) {
          return;
        }
        exportObj['Postcode'] = address.postal;
        exportObj['Huisnummer'] = address.houseNumber;
        exportObj['Toevoeging'] = address.houseNumberAddition;
        exportObj['Type'] = address.type ? address.type.join(', ') : '';
        exportObj['Straatnaam'] = address.street ? address.street : '';
        exportObj['Woonplaats'] = address.place ? address.place : '';
        exportArray.push(exportObj);
      });
    });
    if (exportArray.length === 0) {
      exportArray.push({
        Postcode: '',
        Huisnummer: '',
        Toevoeging: '',
        Type: '',
        Straatnaam: '',
        Woonplaats: '',
      });
    }
    console.log('exportArray', exportArray);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Addressenlijst');

    // /* save to file */
    XLSX.writeFile(wb, this.env.name + '.' + 'Addressenlijst' + '.xlsx');
  }
  async exportPostals() {
    const exportArray = [];
    if (this.township.postals) {
      this.township.postals.forEach((postal) => {
        const exportObj = {};
        exportObj['Postcode cijfers'] = postal;
        exportArray.push(exportObj);
      });
    } else {
      exportArray.push({
        'Postcode cijfers': '',
      });
    }
    console.log('exportArray', exportArray);
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Postcodelijst');

    // /* save to file */
    XLSX.writeFile(wb, this.env.name + '.' + 'Postcodelijst' + '.xlsx');
  }

  tags() {
    if (this.formChanged) {
      // show modal
      this.dialog.open(ContinueComponent, {
        width: '373px',
        // data: { type },
      });
    } else {
      this.router.navigateByUrl('/tags');
    }
  }
  colorChanged(color, colorController) {
    colorController.setValue(color);
  }
  urlCheck(saveObj, controller) {
    let invalidUrl = false;

    const expression =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    const regex = new RegExp(expression);

    if (saveObj.match(regex)) {
      console.log('valid URL', saveObj);
    } else {
      invalidUrl = true;
    }

    if (invalidUrl) {
      if (controller === 'terms') {
        this.settingsForm.controls.termsUrl.setErrors({
          notMatched: true,
        });
      } else {
        this.settingsForm.controls.url.setErrors({
          notMatched: true,
        });
      }
      this.saving = false;
      return false;
    } else {
      return true;
    }
  }
  copyUrl(type?): void {
    let requestSaldoUrl = false;
    if (!type) {
      type = '';
    } else {
      if (type === 'saldo') {
        requestSaldoUrl = true;
      } else {
        type = type + '.';
      }
    }
    const el = document.createElement('textarea');
    if (this.env.production && !requestSaldoUrl) {
      const prefix = this.env.prefix;
      el.value = `https://${type}frame.${prefix}bon.nl/request/${localStorage.getItem(
        'township'
      )}/digital`;
    } else if (!this.env.production && !requestSaldoUrl) {
      el.value = `https://dev.${type}frame.lokalebon.nl/request/${localStorage.getItem(
        'township'
      )}/digital`;
    }
    if (this.env.production && requestSaldoUrl) {
      el.value = `https://frame.lokalebon.nl/saldo-checker/${localStorage.getItem(
        'township'
      )}`;
    } else if (!this.env.production && requestSaldoUrl) {
      el.value = `https://dev.frame.lokalebon.nl/saldo-checker/${localStorage.getItem(
        'township'
      )}`;
    }
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.snackBar.open('Link gekopieerd.', 'X', {
      duration: 3000,
    });
  }
  async openFile(type: string): Promise<any> {
    if (type === 'headerImg') {
      if (this.headerImgFileAlreadyUploaded) {
        window.open(this.headerImgUrl);
      }
    } else if (type === 'invoicePdf') {
      if (this.invoicePdfFileAlreadyUploaded) {
        window.open(this.invoicePdfUrl);
      }
    } else if (type === 'voucherPdf') {
      if (this.voucherPdfFileAlreadyUploaded) {
        window.open(this.voucherPdfUrl);
      }
    }
  }

  setDelete(type: string): void {
    const name =
      type === 'invoicePdf' || 'voucherPdf' ? 'PDF' : 'E-mail header';
    if (type === 'headerImg') {
      this.deleteHeaderImg = true;
    } else if (type === 'invoicePdf') {
      this.deleteInvoicePdf = true;
    } else if (type === 'voucherPdf') {
      this.deleteVoucherPdf = true;
    }
    this.snackBar.open(
      `Druk op opslaan om de ${name} definitief te verwijderen`,
      'X',
      {
        duration: 3500,
      }
    );
  }

  deleteFile(type: string, extension: string): void {
    let segment;
    // let extension = '';
    let fileName;
    if (type === 'pdf') {
      segment = 'pdf-templates';
      // extension = '.pdf';
      fileName = this.townshipId;
    } else if (type === 'headerImg') {
      segment = 'images';
      fileName = this.townshipId;
    } else if (type === 'invoicePdf') {
      // extension = '.pdf';
      segment = 'pdf-templates';
      fileName = 'factuur.pdf';
    } else if (type === 'voucherPdf') {
      // extension = '.pdf';
      segment = 'pdf-templates';
      fileName = 'bon.pdf';
    }
    const name = extension === 'pdf' ? 'PDF' : 'E-mail header';
    const fileUrl = `${this.townshipId}/${segment}/${fileName}`;

    console.log('fileUrl', fileUrl);
    const file = this.storage.ref(fileUrl).delete();
    file.subscribe((result) => {
      // this.snackBar.open(`${name} verwijderd`, 'X', {
      //   duration: 5000
      // });
    });
  }

  async openSetupPaymentDialog() {
    const dialogRef = this.dialog.open(SetupPaymentDialogComponent, {
      width: '600px',
      height: 'auto',
      data: { township: this.townshipId },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (result === 'succeed') {
        this.checkboxCheck = true;
      }
    });
  }

  openForceApiSyncDialog(): void {
    this.dialog.open(ForceApiSyncDialogComponent, {
      width: '375px',
      // data: { type },
    });
  }
}
