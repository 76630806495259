import { Component, OnInit, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

import { MatSnackBar } from '@angular/material/snack-bar';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  Validators,
} from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

import { environment } from '../../../environments/environment';

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: FormControl | null,
    form: FormGroupDirective | NgForm | null
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(
      control &&
      control.invalid &&
      (control.dirty || control.touched || isSubmitted)
    );
  }
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  // video: string = environment.login.video;
  env = environment;
  image: string = environment.login.image;
  name: string = environment.name;
  prefix: string = environment.phone.nl;
  loginMethodEmail = environment.loginMethod.email;
  envName: string;
  // environment = environment.env;

  email: string;
  password: string;
  loginForm: FormGroup;
  loading: boolean;
  showPassword: boolean;

  // emailFormControl = new FormControl('', [
  //   Validators.required,
  //   Validators.email,
  // ]);
  matcher = new MyErrorStateMatcher();
  errorLoginMessage: string;
  user: any;
  constructor(
    private fb: FormBuilder,
    public afAuth: AngularFireAuth,
    private router: Router,
    private snackBar: MatSnackBar
  ) {}
  ngOnInit() {
    this.loginForm = this.fb.group({
      email: [, Validators.required],
      password: [, [Validators.required]],
    });
    if (this.env.env === 'township') {
      this.envName = 'beheerder';
    } else {
      this.envName = 'ondernemer';
    }
  }

  // Login with Email
  async mailLogin() {
    if (this.loginForm.valid && !this.loading) {
      this.loading = true;
      this.afAuth
        .signInWithEmailAndPassword(
          this.loginForm.value.email,
          this.loginForm.value.password
        )
        .then(() => {
          this.router.navigate(['/dashboard-township'], {});
        })
        .catch((error) => {
          // Handle Errors here.
          const errorCode = error.code;
          const errorMessage = error.message;
          this.errorLoginMessage =
            'De combinatie e-mail en wachtwoord is niet correct.';
          this.loading = false;
          console.log(errorCode, errorMessage);
          // this._snackBar.open('Email en/of wachtwoord is incorrect ingevult', 'X', {
          //   duration: 5000,
          // });
          // ...
        });
    }
  }
  async resetPassword() {
    if (!this.loginForm.value.email) {
      this.errorLoginMessage =
        'Voer het mailadres van een account in om het wachtwoord te resetten.';
      return;
    }
    try {
      this.afAuth.sendPasswordResetEmail(this.loginForm.value.email);
      this.errorLoginMessage = '';
      this.snackBar.open(
        'Er wordt een wachtwoord reset link verstuurd indien er een account bekend is op het mailadres',
        'X',
        {
          duration: 5000,
        }
      );
    } catch (err) {
      this.errorLoginMessage =
        'Er is iets fout gegaan, probeer het later opnieuw.';
    }
  }
  getError(name) {
    const field = this.loginForm.get(name);
    if (field.touched || !field.pristine) {
      if (field.hasError('required')) {
        return 'Dit veld moet ingevuld zijn.';
      } else if (field.hasError('email')) {
        return 'Vul een geldig e-mailadres in.';
      }
      return '';
    }
  }
}
