import { Component, OnInit, Inject } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { VoucherGroup, Voucher, Tag, Township } from 'src/app/interfaces';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime } from 'rxjs/operators';

export interface DialogData {
  prefix: string;
  suffix: string;
  voucherGroupId: string;
}

@Component({
  selector: 'app-generate-vouchers',
  templateUrl: './generate-vouchers.component.html',
  styleUrls: ['./generate-vouchers.component.scss'],
})
export class GenerateVouchersComponent implements OnInit {
  generateVouchersForm: FormGroup;

  prefix: string;
  suffix: string;

  townshipId = localStorage.getItem('township');
  voucherTemplate: Voucher[] = [];

  parentData: Voucher[] = [];

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<GenerateVouchersComponent>,
    private fb: FormBuilder,
    public db: AngularFirestore,
    public _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this.generateVouchersForm = this.fb.group({
      number: [, [Validators.min(1), Validators.required]],
      value: [, [Validators.min(0), Validators.required]],
    });

    console.log('data', this.data);
    this.prefix = this.data.prefix;
    this.suffix = this.data.suffix;

    this.generateVouchersForm.valueChanges
      .pipe(debounceTime(300))
      .subscribe((result) => {
        if (this.generateVouchersForm.valid) {
          const number = this.generateVouchersForm.value.number;
          const couponValue = this.generateVouchersForm.value.value;

          this.parentData = [];
          this.parentData.push(number);
          this.parentData.push(couponValue);
          // }
        }
      });
  }

  close() {
    this.dialogRef.close();
  }

  showSnackbar(message: string, duration: number) {
    this._snackBar.open(message, 'X', {
      duration: duration,
    });
  }
}
