import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import {
  AngularFirestore,
  AngularFirestoreDocument,
} from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { environment } from '../../environments/environment';
import { Township, User } from '../interfaces';

import { SendVoucherComponent } from '../voucher-management/send-voucher/send-voucher.component';
import { ManageVoucherComponent } from '../voucher-management/manage-voucher/manage-voucher.component';
import { UpgradeDialogComponent } from './dialogs/upgrade-dialog/upgrade-dialog.component';
import { ConfirmationComponent } from '../voucher-management/dialogs/confirmation-code/confirmation-code.component';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit {
  env = environment;
  userDoc: AngularFirestoreDocument<User>;
  userId: Observable<string>;
  user: Observable<User>;
  userData: User;
  townshipDoc: AngularFirestoreDocument<Township>;
  $township: Observable<Township>;
  township: Township;
  fixed = true;
  envName: string;
  // select_entity: boolean;
  userEmail: string;
  // isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  //   .pipe(
  //     map(result => result.matches)
  //   );

  constructor(
    private route: Router,
    public afAuth: AngularFireAuth,
    public afs: AngularFirestore,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  async logout() {
    await this.afAuth.signOut();
    return this.route.navigate(['/login']);
  }

  async openSendVoucherDialog() {
    console.log('User pressed button');
    if (!this.userData) {
      return; // err
    }
    this.dialog.open(SendVoucherComponent, {
      width: '373px',
      data: { townshipId: this.userData.township },
    });
  }
  openEditVoucherDialog(type: string) {
    this.dialog.open(ManageVoucherComponent, {
      width: '373px',
      data: { type },
    });
  }
  openDiscountDialog(): void {
    this.dialog.open(ConfirmationComponent, {
      width: '373px',
      // data: { type },
    });
  }
  showUpdate() {
    const dialogRef = this.dialog.open(UpgradeDialogComponent, {
      width: '500px',
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  ngOnInit() {
    console.log('evn', this.env.env);
    if (this.env.env === 'township') {
      this.envName = 'Beheerder';
    } else {
      this.envName = 'Ondernemer';
    }
    this.afAuth.user.subscribe((user) => {
      if (user) {
        // console.log('user', user);
        this.userEmail = user.email;
        this.userDoc = this.afs.doc<User>('users/' + user.uid);
        this.user = this.userDoc.valueChanges();
        this.user.subscribe((res) => {
          console.log('UserData =>', res);
          this.userData = res;
          if (this.userData.township) {
            this.townshipDoc = this.afs.doc<Township>(
              `township/${this.userData.township}`
            );
            this.$township = this.townshipDoc.valueChanges();
            this.$township.subscribe((value) => {
              this.township = value;
              console.log('township', this.township);
            });
            if (
              (this.env.env === 'township' &&
                (!this.userData.township || this.userData.organisation)) ||
              (this.env.env === 'organisation' &&
                (!this.userData.township || !this.userData.organisation))
            ) {
              // Sign user out if it's an organisation account in township env
              this.logout();
              this.snackBar.open(
                'Je hebt geen rechten om in deze omgeving in te loggen.',
                '',
                {
                  duration: 10000,
                }
              );
            }
          }
        });
        console.log('environment.name', environment.name);
      }
    });
    // this.afAuth.auth.onAuthStateChanged(function(user) {
    //   if (user) {
    //     console.log("user =>", user.email)
    //     this.checkUser = true;
    //   } else {
    //     // User is signed out.
    //     // ...
    //   }
    // });
  }
}
